import axios from "axios";
// let base = "http://localhost:8000/api"
 if (process.env.NODE_ENV === 'development') {
  //  base = location.protocol+'localhost:8000/api'
  axios.defaults.baseURL = 'http://192.168.1.95:8000/api'
  // axios.defaults.baseURL = 'https://192.168.1.95/api/server.php/api'
  // axios.defaults.baseURL = 'https://trinityauto.web.id/tss/server.php/api'
 }else{
  //  base = window.location.origin+'/tss/server.php/api'
  axios.defaults.baseURL = window.location.origin+'/tss/server.php/api'
 }
export default axios.create({
  // baseURL: "https://192.168.1.189/tss/server.php/api",
  // baseURL: "https://147.139.189.197/tss/server.php/api",
  // baseURL: "https://trinityauto.web.id/tss/server.php/api",
  baseURL: undefined,
  // baseURL: "http://192.168.1.189/tss/server.php/api",
  // baseURL: "http://110.139.169.149/tss/server.php/api",
  
  
  headers: {
    "Content-type": "application/json",
  }
});