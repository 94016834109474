<template>
  <v-dialog
    scrollable
    persistent
    width="350"
    v-model="SettingIsOpen"
    class="elevation-15"
  >
    <v-card
      outlined
      rounded="lg"
    >
      <v-card-actions class="mb-n1">
        <span class="title">Privasi</span>
        <v-spacer></v-spacer>
        <v-btn @click.stop="TutupSetting" icon class="mr-n2"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-actions>

      <v-card-text>
        <div class="d-flex justify-center mt-6 mb-8">
          <v-card
            width="90"
            color="grey darken-2"
            class="rounded-circle elevation-8"
          >
            <v-img
              width="90"
              height="90"
              class="rounded-pill"
              :src="ServerPhoto+DataAcc.Photo"
            > 
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon x-large dark>mdi-account</v-icon>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </div>

        <!-- <div class="text-center title mt-3 mb-3" v-text="DataAcc.Nama"></div> -->

        <v-text-field
          prepend-icon="mdi-account-circle"
          color="dark"
          v-model="DataAcc.Nama"
          disabled
          label="Nama Pengguna"
        ></v-text-field>
        
        <v-text-field
          :prepend-icon="password1 != 0 ? 'mdi-lock-check' : 'mdi-lock-open-variant'"
          color="dark"
          v-model="password1"
          label="Password Baru"
          :append-icon="TampilkanSandi ? 'mdi-eye' : 'mdi-eye-off'"
          :type="TampilkanSandi ? 'text' : 'password'"
          @click:append="TampilkanSandi = !TampilkanSandi"
        ></v-text-field>

        <v-text-field
          :prepend-icon="password2 != '' ? 'mdi-lock-check' : 'mdi-lock-open-variant'"
          color="dark"
          label="Ulangi Password Baru"
          v-model="password2"
          :append-icon="TampilkanUlangSandi ? 'mdi-eye' : 'mdi-eye-off'"
          :type="TampilkanUlangSandi ? 'text' : 'password'"
          @click:append="TampilkanUlangSandi = !TampilkanUlangSandi"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :dark="password1 != '' ? true : false"
          :disabled="password1 != '' ? false : true"
          block
          @click="SimpanPass"
          depressed
          height="40"
          color="red darken-4"
          class="text-capitalize mb-2 rounded-lg"
        >
          <v-icon left>mdi-pencil</v-icon>
          Simpan Perubahan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Vue from 'vue'
  import Vuetify from 'vuetify/lib'
  import api from "@/services/http";

  Vue.use(Vuetify)
  export default {
    props: ['SettingIsOpen','DataAcc','DataUser'],
    data () {
      return {
        ServerPhoto:process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+":8000" : location.origin+'/tss/public',
        TampilkanSandi: false,
        password1:null,
        password2:null,
        token:localStorage.getItem('token'),
        passbaru:null,
        TampilkanUlangSandi: false,
        Data:this.$user,
        // drawerSetting:true,
      }
    },

    watch:{
      SettingIsOpen(value){
        console.log(value)
        this.password1 = ''
        this.password2 = ''
        this.$parent.SettingIsOpen = value
      }
    },

    methods: {
      SimpanPass(){
        // alert('oke')
        if (this.password1 && this.password2) {
            if (this.password1 == this.password2) {
              this.passbaru = this.password1
            // console.log('tes',this.DataUser)
            this.Toast('Data Di Simpan')
            api.put("/user/"+this.DataUser.id+"?token="+this.token,{
                Kode: this.DataUser.Kode,
                Nama: this.DataUser.Nama,
                password: this.passbaru,
                StatusData: this.DataUser.StatusData,
                KodeKaryawan: this.DataUser.KodeKaryawan,
                UserMenu: this.DataUser.UserMenu
              },
              { headers: {"Authorization" : `Bearer ${this.token}` } })
              .then((res) => {
                if (!res) {
                  this.ToastErr('Kesalahan Jaringan Atau Sistem')
                  //do nothing
                }else{
                  // this.Toast('Data Di Simpan')
                }
              })
              .catch((err)=>{
                console.log(err)
              })
            }else{
              this.ToastErr('Password baru dan ulangi password baru Tidak Sama Silahkan Cek lagi')
           }
        }else{
          this.ToastErr('Tidak ada perubahan')
        }
      },
      toggledarkmode: function () {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
          localStorage.setItem("darktheme", this.$vuetify.theme.dark.toString());
          console.log(this.$vuetify.theme.dark);
      },
      Toast(data){
        this.$swal.fire({
        toast: true,
        icon: 'success',
        title: data,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true})
      },
      ToastErr(data){
        this.$swal.fire({
        toast: true,
        icon: 'error',
        title: data,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true})
      },

      TutupSetting(){
        this.$parent.SettingIsOpen = false
      },
    },
      mounted() {
          this.token = localStorage.getItem('token')
          this.$parent.MenuShowAkun = false
      },
  };
</script>