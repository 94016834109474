import Vue from 'vue'
// import api from "@/services/http";
import VueRouter from 'vue-router'
const Home = () => import (/* webpackChunkName: "Home"*/ '../views/Home.vue')
const About = () => import (/* webpackChunkName: "About"*/ '../views/About.vue')
const Login = () => import (/* webpackChunkName: "Login"*/ '../components/Login.vue')
const Register = () => import(/* webpackPrefetch: true */ '../components/Register.vue')
const Karyawan = () => import(/* webpackPrefetch: true */ '../components/Karyawan.vue')
const Jabatan = () => import(/* webpackPrefetch: true */ '../components/Jabatan.vue')
const Cabang = () => import(/* webpackPrefetch: true */ '../components/Cabang.vue')
const KontrakKaryawan = () => import(/* webpackPrefetch: true */ '../components/KontrakKaryawan.vue')
const KategoriAbsen = () => import(/* webpackPrefetch: true */ '../components/KategoriAbsen.vue')
const User = () => import(/* webpackPrefetch: true */ '../components/User.vue')
const UserMenu = () => import(/* webpackPrefetch: true */ '../components/UserMenu.vue')
const Log = () => import(/* webpackPrefetch: true */ '../components/Log.vue')
const Perusahaan = () => import(/* webpackPrefetch: true */ '../components/Perusahaan.vue')
const PerusahaanCabang = () => import(/* webpackPrefetch: true */ '../components/PerusahaanCabang.vue')
const Absen = () => import(/* webpackPrefetch: true */ '../components/Absen.vue')
const PenugasanKaryawan = () => import(/* webpackPrefetch: true */ '../components/PenugasanKaryawan.vue')
const Payroll = () => import(/* webpackPrefetch: true */ '../components/Payroll.vue')
const KeteranganAbsen = () => import(/* webpackPrefetch: true */ '../components/KeteranganAbsen.vue')
const Cuti = () => import(/* webpackPrefetch: true */ '../components/Cuti.vue')
const ApprovalCuti = () => import(/* webpackPrefetch: true */ '../components/ApprovalCuti.vue')
const ApprovalPenugasan = () => import(/* webpackPrefetch: true */ '../components/ApprovalPenugasan.vue')
const JabatanKaryawan = () => import(/* webpackPrefetch: true */ '../components/JabatanKaryawan.vue')
const PesanMasuk = () => import(/* webpackPrefetch: true */ '../components/PesanMasuk.vue')
const AppSetting = () => import(/* webpackPrefetch: true */ '../components/AppSetting.vue')
const FormCutiKaryawan = () => import(/* webpackPrefetch: true */ '../components/FormCutiKaryawan.vue')
const Chat = () => import(/* webpackPrefetch: true */ '../components/Chat.vue')
const SuratCuti = () => import(/* webpackPrefetch: true */ '../views/FormCuti/SuratCuti.vue')
const MasterMenu = () => import(/* webpackPrefetch: true */ '../views/Setting/MasterMenu.vue')
const CameraQr = () => import(/* webpackPrefetch: true */ '../components/CameraQrCode.vue')
const AbsenQr = () => import(/* webpackPrefetch: true */ '../components/AbsenQrCode.vue')
const Notifikasi = () => import(/* webpackPrefetch: true */ '../components/Notifikasi.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      auth: true,
    },
  },
  {
    path: '/karyawan',
    name: 'Karyawan',
    component: Karyawan,
    meta: {
      auth: true,
    },
  },
  {
    path: '/jabatan',
    name: 'Jabatan',
    component: Jabatan,
    meta: {
      auth: true,
    },
  },
  {
    path: '/perusahaan',
    name: 'Perusahaan',
    component: Perusahaan,
    meta: {
      auth: true,
    },
  },
  {
    path: '/KontrakKaryawan',
    name: 'KontrakKaryawan',
    component: KontrakKaryawan,
    meta: {
      auth: true,
    },
  },
  {
    path: '/KategoriAbsen',
    name: 'KategoriAbsen',
    component: KategoriAbsen,
    meta: {
      auth: true,
    },
  },
  {
    path: '/cabang',
    name: 'Cabang',
    component: Cabang,
    meta: {
      auth: true,
    },
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      auth: true,
    },
  },
  {
    path: '/usermenu',
    name: 'UserMenu',
    component: UserMenu,
    meta: {
      auth: true,
    },
  },
  {
    path: '/log',
    name: 'Log',
    component: Log,
    meta: {
      auth: true,
    },
  },
  {
    path: '/PerusahaanCabang',
    name: 'PerusahaanCabang',
    component: PerusahaanCabang,
    meta: {
      auth: true,
    },
  },
  {
    path: '/Absen',
    name: 'Absen',
    component: Absen,
    meta: {
      auth: true,
    },
  },
  {
    path: '/PenugasanKaryawan',
    name: 'PenugasanKaryawan',
    component: PenugasanKaryawan,
    meta: {
      auth: true,
    },
  },
  {
    path: '/Payroll',
    name: 'Payroll',
    component: Payroll,
    meta: {
      auth: true,
    },
  },
  {
    path: '/KeteranganAbsen',
    name: 'KeteranganAbsen',
    component: KeteranganAbsen,
    meta: {
      auth: true,
    },
  },
  {
    path: '/Cuti',
    name: 'Cuti',
    component: Cuti,
    meta: {
      auth: true,
    },
  },
  {
    path: '/ApprovalCuti',
    name: 'ApprovalCuti',
    component: ApprovalCuti,
    meta: {
      auth: true,
    },
  },
  {
    path: '/ApprovalPenugasan',
    name: 'ApprovalPenugasan',
    component: ApprovalPenugasan,
    meta: {
      auth: true,
    },
  },
  {
    path: '/JabatanKaryawan',
    name: 'JabatanKaryawan',
    component: JabatanKaryawan,
    meta: {
      auth: true,
    },
  },
  {
    path: '/PesanMasuk',
    name: 'PesanMasuk',
    component: PesanMasuk,
    meta: {
      auth: true,
    },
  },
  {
    path: '/AppSetting',
    name: 'AppSetting',
    component: AppSetting,
    meta: {
      auth: true,
    },
  },
  {
    path: '/FormCutiKaryawan',
    name: 'FormCutiKaryawan',
    component: FormCutiKaryawan,
    meta: {
      auth: true,
    },
  },
  {
    path: '/Chat',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/SuratCuti/:perusahaan/:kode/:tahun/:no',
    name: 'SuratCuti',
    component: SuratCuti,
    meta: {
      auth: false,
    },
  },
  {
    path: '/MasterMenu',
    name: 'MasterMenu',
    component: MasterMenu,
    meta: {
      auth: true,
    },
  },
  {
    path: '/CameraQr',
    name: 'CameraQr',
    component: CameraQr,
    meta: {
      auth: false,
    },
  },
  {
    path: '/AbsenQr/:kodecabang',
    name: 'AbsenQr',
    component: AbsenQr,
    meta: {
      auth: false,
    },
  },
  {
    path: '/Notifikasi',
    name: 'Notifikasi',
    component: Notifikasi,
    meta: {
      auth: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// let token = null
// let menu = []
// const getdata = async () => {
//   await api.get('/menu?token='+token).then(
//     res=>{
//       if(res){
//         //define hasil menu
//         menu = res.data   
//         console.log('menuasync',res.data)
//         // to.params['Menu'] = menu
//       }else{
//         // next('/login')
//       }
      
//     }).catch(err=>{
//             // alert(err)
//             console.log('haduh',err)
//           })
// }

//sblm masuk global guard eksekusi
 router.beforeEach((to, from, next) => {
  //  let token = localStorage.getItem('token')
   //di define dlu
  //match halaman dngn metanya
  if(to.matched.some(record => record.meta.auth)){
    //tambahan doang klo null token ke login
    if(localStorage.getItem('token') !== null) {
      //get menu nya
      let menu = JSON.parse(localStorage.getItem('Menu'))
      let izin = null
      if(menu.some(record => record.link == to.name)){
        //define izin cari data menu Route dari database sesuaikan dengan nama route di vue router
          izin =  menu.find(obj => obj.link == to.name)
          console.log(izin)
          //jadikan params dengan key Permission dan value nya Izin dari permision nya
          to.params['Permission'] = izin.Permission
          //next kepagenya
        }
      // token = localStorage.getItem('token')
      // console.log('menueach',menu)
      // if(menu.length == 0){
      //   getdata()
      // }
      
    
    } else {
      // next('/login')
    }
  }
  next()
})

// router.afterEach((to, from) => {
//   //cek lagi kalo memang masih di route yg sama
//   // let izin = []
//   console.log('from',from)
//   let izin = []
//   console.log('menuafter',menu)
//     if(menu.some(record => record.Route == to.name)){
//     //define izin cari data menu Route dari database sesuaikan dengan nama route di vue router
//       izin = menu.find(obj => obj.Route == to.name)
//       //jadikan params dengan key Permission dan value nya Izin dari permision nya
//       to.params['Permission'] = izin.Permission
//       //next kepagenya
//     }
// })

//setelah di eksekusi global guard sblm halaman di resolve dan render
// router.beforeResolve((to,from,next) => {
//   //cek lagi kalo memang masih di route yg sama
//   // let izin = []
//   let izin = []
//     // console.log('tesasync',dat);
//     // return globalData;
//   // console.log('menubefore',menu)
//     if(menu.length == 0){
//       getdata()
//     }else{
//       if(menu.some(record => record.Route == to.name)){
//         //define izin cari data menu Route dari database sesuaikan dengan nama route di vue router
//           izin =  menu.find(obj => obj.Route == to.name)
//           console.log(izin)
//           //jadikan params dengan key Permission dan value nya Izin dari permision nya
//           to.params['Permission'] = izin.Permission
//           //next kepagenya
//         }
//     }
    
//   next()
// })


export default router
