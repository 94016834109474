<template>
  <v-app>
    <v-main class="NoneTransition" :class="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'">
      <router-view/>
    </v-main>
    <Appbar v-if="isblock"/>
  </v-app>
</template>

<script>
  import Vue from 'vue'
  import Vuetify from 'vuetify/lib'
  import Appbar from '@/views/Appbar';

  Vue.use(Vuetify)
  // const Appbar = () => import (/* webpackChunkName: "appbar"*/ '@/views/Appbar.vue')
  export default {
    components: {
      Appbar,
    },

    data () {
      return {
        isblock:false,
        rute:null,
        ruteblock:['SuratCuti','AbsenQr','CameraQr']
        // switchModeMalam: ""
      }
    },
    watch:{
      $route(){
        console.log('eyy',this.$route)
        this.rute = this.$route.name
        let check = this.ruteblock.includes(this.$route.name)
        if(check == true){
          this.isblock = false
        }else{
          this.isblock = true
        }
      }
    },
    
  };
</script>

<style>
  @import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
  @import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
  @import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
  @import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
  @import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
  @import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
  @import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";

  .ScrollCSSHide::-webkit-scrollbar {
    display: none;
  }

  /* width */
  /* ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-left: 1px solid #dadada;
  }

  ::-webkit-scrollbar-thumb {
    background: #949494;
    border: solid 3px #e6e6e6;
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(79, 79, 79);
  } */

  /* .dark::-webkit-scrollbar {
    width: 15px;
  }

  .dark::-webkit-scrollbar-track {
    background: #202020;
    border-left: 1px solid #2c2c2c;
  }

  .dark::-webkit-scrollbar-thumb {
    background: #3e3e3e;
    border: solid 3px #202020;
    border-radius: 7px;
  }

  .dark::-webkit-scrollbar-thumb:hover {
    background: white;
  } */

  /* .v-window__container {
    min-height: 65vh;
  } */
  .TelatAbsen{
    background-color:rgb(255, 219, 12);
    }
  .Terlambat{
  background-color:rgb(207, 177, 110);
  }

  .v-navigation-drawer {
    will-change: initial;
  }

  .v-menu {
    will-change: initial !important;
  }

  .NoneTransition {
    transition: none !important
  }
  /* icon syncfusion */
  .mata:before{ 
    content:'\e345'; 
  /* endof icon syncfusion */
} 
</style>