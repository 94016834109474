import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import sweetalert2 from './plugins/sweetalert2';
import './registerServiceWorker'
import QuickEdit from 'vue-quick-edit';
import VueQrcode from '@chenfengyuan/vue-qrcode';
 
Vue.config.productionTip = false
Vue.component('quick-edit', QuickEdit);
Vue.component(VueQrcode.name, VueQrcode);

new Vue({
  router,
  vuetify,
  sweetalert2,
  render: h => h(App)
}).$mount('#app')
