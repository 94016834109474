<template>
  <v-list-item link @change="toggleTheme()">
    <v-list-item-avatar>
      <v-icon v-if="!$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
      <v-icon v-else>mdi-weather-night</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>Mode Malam</v-list-item-title>
      <v-list-item-subtitle v-if="!$vuetify.theme.dark">Tidak Aktif</v-list-item-subtitle>
      <v-list-item-subtitle v-else>Aktif</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-switch
        @change="toggleTheme()"
        v-model="$vuetify.theme.dark"
        hide-details
        color="error"
      ></v-switch>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default {
  data: () => ({
    // switchModeMalam: "",
  }),

  computed: {
    // scrollbarTheme() {
    //   return this.$vuetify.theme.dark ? 'dark' : 'light';
    // },
  },
  
  methods: {
    toggleTheme: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      // this.switchModeMalam = !this.$vuetify.theme.dark;
      localStorage.setItem("darktheme", this.$vuetify.theme.dark.toString());
      console.log(this.$vuetify.theme.dark);
    },
  },

  mounted() {
    const theme = localStorage.getItem("darktheme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
        this.switchModeMalam = this.$vuetify.theme.dark;
      } else {
        this.$vuetify.theme.dark = false;
        this.switchModeMalam = this.$vuetify.theme.dark;
      }
    }
  },
};
</script>