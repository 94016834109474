<template>
  <div id="app" v-resize="onResize">
    <v-overlay v-model="MenuShowAkun" :value="MenuShowAkun"></v-overlay>
    <v-overlay :value="MenuNotifikasi"></v-overlay>
    <v-overlay :value="MenuSetting"></v-overlay>

    <v-app-bar
      app
      elevate-on-scroll
      v-show="this.token !=null == true"
      clipped-right
      height="64px"
      class="NoneTransition"
      :color="$vuetify.theme.dark ? '' : 'white'"
    >
      <v-btn 
        fab small text
        class="ml-n2 mr-1"
        v-show="drawerMenu == false"
        @click.stop="toggle_drawer_menu"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <!-- <v-img v-show="mobile == true" max-width="30" src="../assets/LogoTrinity.png"></v-img> -->

      <v-img v-show="drawerMenu == false && mobile == false" @click="home" class="ml-2 mr-2" max-width="30" src="../assets/LogoTrinity.png" style="cursor: pointer;"></v-img>
      <v-toolbar-title @click="home" v-show="drawerMenu == false && mobile == false">
        <router-link to="home" tag="span" exact style="cursor: pointer;">
          <div class="title mb-n1" v-text="appdata.NamaPerusahaan.Nilai"></div>
          <div class="caption" v-text="appdata.NamaAlias.Nilai"></div>
        </router-link>
      </v-toolbar-title>

      <!-- Akun Mode Mobile Start -->
      <v-toolbar-title
        @click="tampilkan_menu_akun_mobile"
        v-show="mobile == true && valid == true"
        style="cursor: pointer;"
      >
        <v-avatar
          size="39"
          class="ml-1 mr-2"
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" 
        >
          <v-img :src="ServerPhoto+Acc.Photo">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-2'">mdi-account</v-icon>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <span class="subtitle-1 font-weight-bold" v-text="Acc.Nama"></span>
      </v-toolbar-title>
      <v-icon @click="tampilkan_menu_akun_mobile" v-show="mobile == true && valid == true" class="mr-4">mdi-chevron-down</v-icon>
      <v-bottom-sheet
        v-model="MenuShowAkunMobile"
      >
        <v-divider></v-divider>
        <v-sheet>
          <v-list>
            <v-list-item>
              <v-list-item-avatar :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
                <v-img :src="ServerPhoto+Acc.Photo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-2'">mdi-account</v-icon>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><strong v-text="Acc.Nama"></strong></v-list-item-title>
                <v-list-item-subtitle v-text="NamaJabatan.NamaJabatan"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="keluar_menu_akun_mobile"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <!-- <v-list-item @click="setting">
              <v-list-item-avatar>
                <v-icon>mdi-shield-lock</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Privasi</v-list-item-title>
                <v-list-item-subtitle>Ubah Kata Sandi</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item> -->
            <ModeMalam/>
            <!-- <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon color="error">mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="error--text"><strong>Logout</strong></v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </v-sheet>
      </v-bottom-sheet>
      <!-- Akun Mode Mobile End -->

      <v-spacer></v-spacer>

      <!-- Akun Mode Desktop Start -->
      <v-menu  max-width="350" offset-y v-model="MenuShowAkun" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            outlined
            depressed
            height="48" 
            class="subtitle-1 rounded-xl text-capitalize ma-2"
            v-bind="attrs"
            v-on="on"
            v-show="mobile == false && valid == true"
            @click="MenuShowAkun = !MenuShowAkun"
          >
            <v-avatar outlined :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'" size="38" class="ml-n3 mr-2">
              <v-img :src="ServerPhoto+Acc.Photo">  
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-2'">mdi-account</v-icon>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            <span v-text="Acc.Nama"></span>
            <v-icon class="mr-n2 ml-1">{{ MenuShowAkun ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
          </v-btn>
        </template>
        <v-card
          outlined
          rounded="lg"
          v-show="this.token !=null && MenuShowAkun"
        >
          <v-list nav>
            <v-list-item two-line class="mt-n2 mb-n2">
              <v-list-item-avatar outlined size="55" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
                <v-img :src="ServerPhoto+Acc.Photo">
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-row>
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><span v-text="Acc.Nama"></span></v-list-item-title>
                <v-list-item-subtitle v-text="NamaJabatan.NamaJabatan"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list nav>
            <!-- <v-list-item @click="setting">
              <v-list-item-avatar>
                <v-icon :color="$vuetify.theme.dark ? '' : 'black'">mdi-shield-lock</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><strong>Privasi</strong></v-list-item-title>
                <v-list-item-subtitle>Ubah Kata Sandi</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
            <ModeMalam/>
            <!-- <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon :color="$vuetify.theme.dark ? '' : 'black'">mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><strong>Logout</strong></v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </v-card>
      </v-menu>
      <!-- Akun Mode Desktop End -->

      <!-- <div v-show="this.token !=null">
        <v-btn
          v-show="mobile == true && valid == true"
          @click="MenuShowAkunMobile = true"
          class="ma-2 rounded-pill"
          height="48"
          outlined
          text
        >
          <v-avatar class="ml-n3" size="38" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
            <v-img :src="ServerPhoto+Acc.Photo">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-2'">{{ MenuShowAkunMobile ? 'mdi-account' : 'mdi-account-outline' }}</v-icon>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <v-icon class="mr-n3">mdi-menu-down</v-icon>
        </v-btn>
      
        <v-bottom-sheet v-model="MenuShowAkunMobile">
          <v-divider></v-divider>
          <v-card class="tile">
            <v-list nav>
              <v-list-item two-line class="mt-n2 mb-n2">
                <v-list-item-avatar outlined size="55" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
                  <v-responsive :aspect-ratio="1/1">
                    <v-img :src="ServerPhoto+Acc.Photo">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-icon>mdi-account</v-icon>
                        </v-row>
                      </template>
                    </v-img>
                  </v-responsive>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title><strong v-text="Acc.Nama"></strong></v-list-item-title>
                  <v-list-item-subtitle v-text="NamaJabatan.NamaJabatan"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list nav>
              <v-list-item @click="setting">
                <v-list-item-avatar>
                  <v-icon :color="$vuetify.theme.dark ? '' : 'black'">mdi-shield-lock</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title><strong>Privasi</strong></v-list-item-title>
                  <v-list-item-subtitle>Ubah Kata Sandi</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <ModeMalam/>
              
              <v-list-item @click="logout">
                <v-list-item-avatar>
                  <v-icon :color="$vuetify.theme.dark ? '' : 'black'">mdi-logout</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title><strong>Logout</strong></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-bottom-sheet>
      </div> -->

      <!-- Menu Notifikasi Desktop -->
      <v-menu
        v-model="MenuNotifikasi"
        :close-on-content-click="false"
        rounded="lg"
        height="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            text
            outlined
            :color="$vuetify.theme.dark ? '' : 'grey darken-2'"
            v-show="valid == true && mobile == false"
            v-bind="attrs"
            v-on="on"
            @click="MenuNotifikasi = !MenuNotifikasi"
          >
            <v-badge
              v-show="notiflistlength > 0"
              color="red darkne-4"
              :content="notiflistlength"
              bordered
              overlap
              offset-x="-22"
              offset-y="-2"
            >    
            </v-badge>
            <v-icon
              :color="notiflistlength == 0 ? '' : ''"
              v-text="notiflistlength == 0 ? 'mdi-bell' : 'mdi-bell-ring'"
            ></v-icon>
          </v-btn>
        </template>

        <v-card
          outlined
          width="430px"
          rounded="lg"
          
        >
          <v-app-bar
            flat
            height="64"
            class="rounded-t-lg"
            scroll-target="#ScrollListNotif"
            :color="$vuetify.theme.dark ? 'dark' : 'white'"
          >
            <v-toolbar-title class="title">Notifikasi</v-toolbar-title>
            <v-chip small color="error" class="ma-2" v-show="notiflistlength > 0"><span class="white--text body-2" v-text="notiflistlength+' Baru'"></span></v-chip>
            
            <v-spacer></v-spacer>

            <v-btn icon @click="MenuNotifikasi = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>

          <v-divider></v-divider>

          <div id="ScrollListNotif" class="overflow-y-auto" style="height: 500px;">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="text-capitalize ml-2 mt-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span v-show="HariIni" class="ml-n1">Hari Ini</span>
                  <span v-show="BelumTerbaca" class="ml-n1">Belum Terbaca</span>
                  <span v-show="SemuaPesan" class="ml-n1">Semua <span v-text="' ('+notiflist.length+')'" v-show="notiflist.length > 0"></span></span>
                  <v-icon class="mr-n2">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group mandatory>
                  <v-list-item link @click.native="TampilkanNotifHariIni">
                    <v-list-item-title>Hari Ini</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click.native="TampilkanNotifBelumTerbaca">
                    <v-list-item-title>Belum Terbaca</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click.native="TampilkanSemuaNotif">
                    <v-list-item-title>Semua</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>

            <div v-show="loading == true" class="text-center">
              <v-skeleton-loader class="mb-n4" v-for="n in 6" :key="n" type="list-item-three-line"></v-skeleton-loader>
            </div>

            <div v-show="loading == false && SemuaPesan == false && notiflistlength == 0" class="text-center ma-2" style="height: 370px;">
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <span>Belum Ada Notifikasi</span>
              </v-row>
            </div>

            <v-list
              v-show="notiflist.length == 0 == false && loading == false"
              id="ScrollListNotif"
            > 
              <div v-show="loading == false">
                <template v-for="(notif, index) in notiflist">
                  <v-list-item
                    three-line
                    :key="notif.id"
                    @click="BukaNotif(notif)"
                  >
                    <v-list-item-avatar :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
                      <v-icon v-show="notif.Judul == 'Perpanjang Kontrak' == true">mdi-file-document-edit</v-icon>
                      <v-icon v-show="notif.Judul == 'Penugasan Karyawan' == true">mdi-file-document-edit</v-icon>
                      <v-img v-show="notif.Judul == 'Pengajuan Cuti' == true"
                        :src="ServerPhoto+notif.karyawan.Photo">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="mb-1">
                        <span v-show="notif.Judul == 'Pengajuan Cuti' == true" v-text="notif.Judul" :class="notif.Judul == 'Pengajuan Cuti' == true && notif.Dibaca == 0 == true ? 'font-weight-bold' : ''"></span>
                        <span v-show="notif.Judul == 'Perpanjang Kontrak' == true" v-text="notif.Judul" :class="notif.Judul == 'Perpanjang Kontrak' == true && notif.Dibaca == 0 == true ? 'font-weight-bold' : ''"></span>
                        <span v-show="notif.Judul == 'Penugasan Karyawan' == true" v-text="notif.Judul" :class="notif.Judul == 'Penugasan Karyawan' == true && notif.Dibaca == 0 == true ? 'font-weight-bold' : ''"></span>

                        <span v-show="notif.Judul == 'Pengajuan Cuti' == true && notif.Dibaca == 0 == true" class="error--text font-weight-bold"><v-icon small>mdi-circle-medium</v-icon>Baru</span>
                        <span v-show="notif.Judul == 'Perpanjang Kontrak' == true && notif.Dibaca == 0 == true" class="blue--text text--darken-4 font-weight-bold"><v-icon small>mdi-circle-medium</v-icon>Info</span>
                        <span v-show="notif.Judul == 'Penugasan Karyawan' == true && notif.Dibaca == 0 == true" class="blue--text text--darken-4 font-weight-bold"><v-icon small>mdi-circle-medium</v-icon>Info</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span class="text--primary" v-text="notif.karyawan.Nama"></span>
                          <!-- &mdash;
                        <span v-text="notif.Konten"></span> -->
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <!-- <span class="text--primary" v-text="notif.karyawan.Nama"></span>
                          &mdash; -->
                        <span v-text="notif.Konten"></span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle><small class="text--primary" v-text="notif.DiBuatTgl"></small></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider inset
                    v-if="index < notiflist.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </div>
            </v-list>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <!-- <div class="mx-auto">
              Tampilkan Semua
            </div> -->
          </v-card-actions>
        </v-card>
      </v-menu>

      <!-- Btn Home Appbar Mobile -->
      <v-btn @click="home" v-show="mobile == true && valid == true" fab small depressed :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" class="ma-2">
        <router-link to="home" tag="span" exact style="cursor: pointer;">
        <v-icon>mdi-home</v-icon>
        </router-link>
      </v-btn>

      <!-- Menu Notifikasi Mobile -->
      <div>
        <v-btn fab small depressed :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" @click="MenuNotifikasiMobile = true" v-show="mobile == true && valid == true">
          <v-badge
            v-show="notiflistlength > 0"
            color="red darkne-4"
            :content="notiflistlength"
            bordered
            overlap
            offset-x="-15"
            offset-y="-5"
          >    
          </v-badge>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
        
        <v-dialog scrollable fullscreen transition="dialog-bottom-transition" v-model="MenuNotifikasiMobile">
          <v-card
            class="tile"
          >
            <v-card-actions
              style="height: 58px;"
              :class="$vuetify.theme.dark ? '' : 'white'"
            >
              <v-btn icon class="ml-n2 mr-1" height="47px" width="47px" @click="MenuNotifikasiMobile = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title class="title">Notifikasi</v-toolbar-title>
              <v-badge
                v-show="notiflistlength > 0"
                color="red darkne-4"
                :content="notiflistlength+' Baru'"
                bordered
                overlap
                offset-x="-6"
                offset-y="10"
              >    
              </v-badge>
              <v-spacer></v-spacer>
            </v-card-actions>

            <v-divider></v-divider>
            
            <v-card-text>
              <div class="mr-n6 ml-n6 mb-n4">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="text-capitalize ml-2 mt-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span v-show="HariIni" class="ml-n1">Hari Ini</span>
                      <span v-show="BelumTerbaca" class="ml-n1">Belum Terbaca</span>
                      <span v-show="SemuaPesan" class="ml-n1">Semua <span v-text="' ('+notiflist.length+')'" v-show="notiflist.length > 0"></span></span>
                      <v-icon class="mr-n2">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group mandatory>
                      <v-list-item link @click.native="TampilkanNotifHariIni">
                        <v-list-item-title>Hari Ini</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click.native="TampilkanNotifBelumTerbaca">
                        <v-list-item-title>Belum Terbaca</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click.native="TampilkanSemuaNotif">
                        <v-list-item-title>Semua</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

                <div v-show="loading == true" class="text-center">
                  <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
                </div>

                <div v-show="loading == false && SemuaPesan == false && notiflistlength == 0" class="text-center ma-2">
                  <span>Belum Ada Notifikasi</span>
                </div>
                  
                <v-list v-show="loading == false && notiflist.length == 0 == false">
                  <template v-for="(notif, index) in notiflist">
                    <v-list-item
                      three-line
                      :key="notif.id"
                      @click="BukaNotif(notif)"
                    >
                      <v-list-item-avatar :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
                        <v-icon v-show="notif.Judul == 'Perpanjang Kontrak' == true">mdi-file-document-edit</v-icon>
                        <v-icon v-show="notif.Judul == 'Penugasan Karyawan' == true">mdi-file-document-edit</v-icon>
                        <v-img v-show="notif.Judul == 'Pengajuan Cuti' == true"
                          :src="ServerPhoto+notif.karyawan.Photo">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-icon>mdi-account</v-icon>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="mb-1">
                          <span v-show="notif.Judul == 'Pengajuan Cuti' == true" v-text="notif.Judul" :class="notif.Judul == 'Pengajuan Cuti' == true && notif.Dibaca == 0 == true ? 'font-weight-bold' : ''"></span>
                          <span v-show="notif.Judul == 'Perpanjang Kontrak' == true" v-text="notif.Judul" :class="notif.Judul == 'Perpanjang Kontrak' == true && notif.Dibaca == 0 == true ? 'font-weight-bold' : ''"></span>
                          <span v-show="notif.Judul == 'Penugasan Karyawan' == true" v-text="notif.Judul" :class="notif.Judul == 'Penugasan Karyawan' == true && notif.Dibaca == 0 == true ? 'font-weight-bold' : ''"></span>

                          <span v-show="notif.Judul == 'Pengajuan Cuti' == true && notif.Dibaca == 0 == true" class="error--text font-weight-bold"><v-icon small>mdi-circle-medium</v-icon>Baru</span>
                          <span v-show="notif.Judul == 'Perpanjang Kontrak' == true && notif.Dibaca == 0 == true" class="blue--text text--darken-4 font-weight-bold"><v-icon small>mdi-circle-medium</v-icon>Info</span>
                          <span v-show="notif.Judul == 'Penugasan Karyawan' == true && notif.Dibaca == 0 == true" class="blue--text text--darken-4 font-weight-bold"><v-icon small>mdi-circle-medium</v-icon>Info</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="text--primary" v-text="notif.karyawan.Nama"></span>
                            <!-- &mdash;
                          <span v-text="notif.Konten"></span> -->
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <!-- <span class="text--primary" v-text="notif.karyawan.Nama"></span>
                            &mdash; -->
                          <span v-text="notif.Konten"></span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle><small class="text--primary" v-text="notif.DiBuatTgl"></small></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset
                      v-if="index < notiflist.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>    
    </v-app-bar>

    <!-- Navigation Drawer -->
    <v-navigation-drawer
      fixed
      floating
      :width="mobile ? '325px' : '285px'"
      :app="valid == true"
      v-model="drawerMenu"
      v-show="valid == true"
      class="NoneTransition"
      :color="$vuetify.theme.dark ? '#272727' : 'white'"
    >
      <template v-slot:prepend>
        <v-toolbar flat height="64px">
          <v-app-bar-nav-icon
            @click.stop="toggle_drawer_menu"
          ></v-app-bar-nav-icon>

          <v-img @click="home" class="ml-2 mr-2" max-width="30" src="../assets/LogoTrinity.png" style="cursor: pointer;"></v-img>

          <v-toolbar-title @click="home">
            <router-link to="home" tag="span" exact style="cursor: pointer;">
              <div class="title mb-n1" v-text="appdata.NamaPerusahaan.Nilai"></div>
              <div class="caption" v-text="appdata.NamaAlias.Nilai"></div>
            </router-link>
          </v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>
      </template>

      <!-- Menu Beranda -->
      <v-list>
        <v-list-item-group
          :color="$vuetify.theme.dark ? 'grey lighten-3' : 'grey darken-4'"
        >
          <v-list-item
            v-for="(item,key) in Beranda"
            :key="key"
            :to="item.link"
            link
          >
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar v-bind="attrs" v-on="on" size="35" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-bind="attrs" v-on="on">
                  <v-list-item-title :class="mobile ? 'subtitle-1' : ''"><span>{{ item.title }}</span></v-list-item-title>
                </v-list-item-content>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip> -->

            <v-list-item-avatar size="35" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title :class="mobile ? 'subtitle-1' : ''"><span>{{ item.title }}</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <!-- Master -->
        <v-list-group
          :color="$vuetify.theme.dark ? 'grey lighten-3' : 'grey darken-4'"
          v-for="item in Menu" :key="item.title"
          v-model="item.active"
          append-icon="mdi-menu-down"
          no-action
        >
          <template v-slot:activator>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar v-bind="attrs" v-on="on" size="35" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-avatar>

                <v-list-item-content v-bind="attrs" v-on="on">
                  <v-list-item-title :class="mobile ? 'subtitle-1' : ''" v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip> -->

            <v-list-item-avatar size="35" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title :class="mobile ? 'subtitle-1' : ''" v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-divider></v-divider>

          <v-list-item
            v-for="child in item.ListMenu"
            :key="child.title"
            :to="child.link"
            link
          >
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-content class="ml-n1" v-bind="attrs" v-on="on">
                  <v-list-item-title :class="mobile ? 'subtitle-1' : ''" v-text="child.title"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar v-bind="attrs" v-on="on" size="35" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'">
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-avatar>
              </template>
              <span>{{ child.title }}</span>
            </v-tooltip> -->

            <v-list-item-content class="ml-n1">
              <v-list-item-title :class="mobile ? 'subtitle-1' : ''" v-text="child.title"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar size="35" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'">
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-avatar>
          </v-list-item>
          
          <v-divider></v-divider>
        </v-list-group>
      </v-list>
      
      <!-- nama akun slide menu -->
      <template v-slot:append>
        <v-divider></v-divider>
        <v-list-item @click="tampilkan_menu_akun_mobile" v-show="mobile == true">
          <v-list-item-avatar :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
            <v-img :src="ServerPhoto+Acc.Photo">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-2'">mdi-account</v-icon>
                </v-row>
              </template>
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title><strong v-text="Acc.Nama"></strong></v-list-item-title>
            <v-list-item-subtitle v-text="NamaJabatan.NamaJabatan"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <Setting v-show="SettingIsOpen == true" :SettingIsOpen="SettingIsOpen" :DataAcc="Acc" :DataUser="user"/>
  </div>
</template>

<script>
import api from "@/services/http";
// import { EventNotif } from "@/notifikasi.js"
import Vue from 'vue'
// const ModeMalam = () => import (/* webpackChunkName: "ModeMalam"*/ '@/views/ModeMalam.vue')
  import ModeMalam from '@/views/ModeMalam';
  import Setting from '@/views/Setting/Setting.vue';
  // import Notifikasi from '@/views/Notifikasi/NotifikasiList.vue';
  export default {
    components: {
      ModeMalam,Setting,
    },
    data: () => {
      return {
        ShowListAkun: false,
        Notifikasi: false,
        MenuNotifikasiMobile: false,
        DialogKetikaLogout: false,
        TabNotif: null,
        HariIni: true,
        BelumTerbaca: false,
        SemuaPesan: false,
        // notifgroup: null,
        MenuSetting: false,
        MenuNotifikasi: false,
        loading:false,
        // value: 1,
        // notifbtnaction: false,
        ServerIcon: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/img/icons/notifikasi.png' : window.location.origin+'/tssweb/img/icons/notifikasi.png',
        // 'https://trinityauto.web.id/tssweb/img/icons/notifikasi.png',
        // ServerIcon:'http://localhost:8080/img/icons/notifikasi.png',
        ServerPhoto:process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+":8000" : location.origin+'/tss/public',
        alertNotifPesan: true,
        MenuShowAkunMobile: false,
        SettingIsOpen:false,
        show: false,
        mobile:null,
        kontrakjt:[],
        rute:[],
        notified:!localStorage.getItem('notified') ? new Array() : JSON.parse(localStorage.getItem('notified')),
        valid:false,
        interval:'',
        connection: null,
        notiflist:[],
        windowSize: {x: 0, y: 0},
        sheet: false,
        notiflistlength:0,
        notiflistKontraklength:0,
        appdata:{
          NamaApp:{
            Nilai:''
          },
          NamaAlias:{
            Nilai:''
          },
          NamaPerusahaan:{
            Nilai:''
          }
        },
        Acc:[],
        NamaJabatan:[],
        TextGetNotif:'notifikasiusertoday',
        accountshow: false,
        notifshow: false,
        MenuShowAkun: true,
        drawerMenu: false,
        token:null,
        user:[],

        // Menu Navigation Drawer

        // Beranda
        Beranda: [
          // { title: 'Beranda', icon: 'mdi-home', link: '/' },
          
        ],

        // Menu
        Menu: [
          // Master
          // {
          //   action: 'mdi-database',
          //   ListMenu: [
          //     { title: 'Karyawan', icon: 'mdi-badge-account-horizontal' , link: 'Karyawan' }
          //   ],
          //   title: 'Master',
          // },

          // // Proses
          // {
          //   action: 'mdi-database',
          //   ListMenu: [
              
          //   ],
          //   title: 'Proses',
          // },
        ],
      }
    },

    beforeMount(){
      this.token = localStorage.getItem('token')
      // ini login trigger
      if (this.token != null || this.token != 'undefined'){
      this.$root.$on('menuappbar', () => {
        this.token = localStorage.getItem('token')
        this.getapp()
        this.menu('First Login')
        this.saya()
        this.user = JSON.parse(localStorage.getItem('user'))})
      }
      Vue.prototype.$CekNotifKontrak = this.getnotifKontrak
      this.getapp()
      this.menu('Logged')
      this.user = JSON.parse(localStorage.getItem('user'))
      // this.getnotif()
    },
    
    mounted(){
      const theme = localStorage.getItem("darktheme");
      if (theme) {
        if (theme == "true") {
          this.$vuetify.theme.dark = true;
          this.switchModeMalam = this.$vuetify.theme.dark;
        } else {
          this.$vuetify.theme.dark = false;
          this.switchModeMalam = this.$vuetify.theme.dark;
        }
      }

      // this.rute = this.$route
      // this.interval = setInterval(function() {this.getnotif(); }, 6500);
      // EventNotif 
      // const ws = new WebSocket('ws://localhost:7007')
      // ws.addEventListener('open', e => {
      //   ws.send(this.$user.Nama+" Terhubung")
      //   console.log('Terhubung Dengan WebSocket',e)
      // })
      // ws.addEventListener('message', () => {
      //   console.log('awokaowkw')
      // })
    },
    created(){
      // this.interval = setInterval(() => {
      //         this.getnotif()
      //       }, 6500);
      this.NotifSignal()
      // this.interval = setInterval(async () => {
      //     const promise = new Promise((resolve) => {
      //       setTimeout(resolve(this.getnotif()), 5000);
      //     });
      //     await promise;
      //   }, 5000);
      // Vue.prototype.$notif = this.getnotif()
    },
    beforeDestroy() {
    this.$root.$off("menuappbar");
    clearInterval(this.interval)
    },
    // beforeUnmount(){
      
    // },

    computed: {
      
    },

    watch: {
      $route(){
        // this.saya()
        let localparam = localStorage.getItem('query') || 'kosong'
        if (localparam != 'kosong') {
          this.$router.push({ name: 'KontrakKaryawan', params:{notifikasi:localStorage.getItem('query')}})
          localStorage.removeItem('query')
        }
        if(this.$route.name != 'Home' && this.$route.name != 'Login'){
          this.getnotif()
        }
         if (this.$route.name == 'KontrakKaryawan' && this.$route.params.notifikasi) {
          //  alert('notif')
         }
      },
      windowSize(){
        if (this.windowSize.x < 600) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false
        }
      },
    },

    methods: {
      tampilkan_menu_akun_mobile() {
        this.MenuShowAkunMobile = true
        this.drawerMenu = false
      },

      keluar_menu_akun_mobile () {
        this.MenuShowAkunMobile = false
      },

      toggleTheme: function () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        // this.switchModeMalam = !this.$vuetify.theme.dark;
        localStorage.setItem("darktheme", this.$vuetify.theme.dark.toString());
        console.log(this.$vuetify.theme.dark);
      },

      toggle_drawer_menu() {
        this.drawerMenu = !this.drawerMenu;
        this.ShowListAkun = false
      },

      toggle_dark_mode() { 
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("darktheme", this.$vuetify.theme.dark.toString());
        console.log('dark mode',this.$vuetify.theme.dark);    
      },

      BukaNotif(notif){
        //kondisi buka notif kontrak karyawan
        if (notif.Judul == "Perpanjang Kontrak" && notif.Kategori == 'Info' && notif.Dibaca == 0) {
            //kondisi tidak sedang di dalam route
            if(this.$route.name != "KontrakKaryawan"){
              this.$router.push({ name: 'KontrakKaryawan', params: { notifikasi: notif } })
              this.MenuNotifikasi = false
              this.MenuNotifikasiMobile = false
            }else{
              this.$BukaNotifKontrak(notif)
              this.MenuNotifikasi = false
              this.MenuNotifikasiMobile = false
            }
        }
        if (notif.Judul == "Pengajuan Cuti" && notif.Kategori == 'Approval' && notif.Dibaca == 0) {
            //kondisi tidak sedanng di dalam route
            if(this.$route.name != "ApprovalCuti"){
              this.$router.push({ name: 'ApprovalCuti', params: { notifikasi: notif } })
              this.MenuNotifikasi = false
              this.MenuNotifikasiMobile = false
            }else{
              // this.$BukaNotifKontrak(notif)
              this.$BukaNotifPengajuanCuti(notif)
              this.MenuNotifikasi = false
              this.MenuNotifikasiMobile = false
            }
        }
        if (notif.Judul == "Pengajuan Cuti" && notif.Kategori == 'Info' && notif.Dibaca == 0) {
            //kondisi tidak sedanng di dalam route
            this.BacaNotif(notif)
            // if(this.$route.name != "ApprovalCuti"){
            //   this.$router.push({ name: 'ApprovalCuti', params: { notifikasi: notif } })
            // }else{
            //   // this.$BukaNotifKontrak(notif)
            //   this.$BukaNotifPengajuanCuti(notif)
            // }
        }
        if (notif.Judul == "Penugasan Karyawan" && notif.Kategori == 'Approval' && notif.Dibaca == 0) {
            //kondisi tidak sedanng di dalam route
            if(this.$route.name != "ApprovalPenugasan"){
              this.$router.push({ name: 'ApprovalPenugasan', params: { notifikasi: notif } })
              this.MenuNotifikasi = false
              this.MenuNotifikasiMobile = false
            }else{
              // this.$BukaNotifKontrak(notif)
              this.$BukaNotifPenugasanKaryawan(notif)
              this.MenuNotifikasi = false
              this.MenuNotifikasiMobile = false
            }
        }
        // console.log('cibaduyut',notif)
      },
      BacaNotif(data){
        this.ToastDibaca()
          api.put('/bacanotifikasi/'+data.id+'?token='+this.token,{
            Dibaca: '1',
					})
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              // this.AlertBerhasilTerubah = true
						}
						
					})
      },
      NotifSignal(){
        setTimeout(() => {
          this.getnotif()
          this.NotifSignal()
        }, 6500);
      },
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
      home(){
        if (this.$router.currentRoute.name == "Home") {
          //do nothing
        }else{
          this.$router.push("/")
        }
      },
      setting(){
        this.drawerMenu = false
        this.SettingIsOpen = true
        this.MenuShowAkun = false
        this.MenuShowAkunMobile = false
        // alert(this.SettingIsOpen)
        
      }, 
      getapp(){
        api.get('/appsetting?token='+this.token).then(
        res=>{
          // console.log('guud',res.data)
          this.appdata.NamaApp = res.data.filter(data => data.Nama == 'NamaApp')[0]
          this.appdata.NamaAlias = res.data.filter(data => data.Nama == 'NamaAlias')[0]
          this.appdata.NamaPerusahaan = res.data.filter(data => data.Nama == 'NamaPerusahaan')[0]
          // console.log('sip',this.appdata)
        })
      },
      saya(){
        // console.log('saya jalan sekali')
        if (this.$route.name == "Register") {
          //do nothing
        }else{
        // api.get('/saya',{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
          api.get('/saya?token='+this.token).then(
        res=>{
          if (res) {
            this.Acc = res.data
            this.NamaJabatan = res.data.jabatan
            Vue.prototype.$user = res.data
            localStorage.setItem('karyawan',JSON.stringify(res.data))
            this.getnotif()
            // if (res.data.jabatan.NamaJabatan == 'Human Resources') {
              if (res.data.jabatan.NamaJabatan.includes('Human Resources')) {
              this.getnotifKontrak()
            }
            // console.log('re',res.data.jabatan.Departemen)
            this.valid = true
            // Vue.prototype.$saya = this.Acc
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" || "Error: Network Error" && this.$route.path != "/login") {
            this.logout()
          }
        })
        }
        
      },
      menu(cek){
      // console.log()
      if (this.token == null || this.token == 'undefined') {
        //do nothing
        if (this.$router.history.current.path != '/login'){
          this.$router.replace('login').catch(err => { console.log('error cuk',err)})
        }
          // this.$router.replace("/login")
          // console.log(this.$router)
          // console.log('awokwaok kosong token')
      }else{
        if (cek == 'Logged') { this.saya() }
        api.get('/menu?token='+this.token).then(
        res=>{
          // console.log('res menu',res)
          Vue.prototype.$menu = res.data
          let tes = []
          // for (let index = 0; index < res.data.length; index++) {
          //   const element = res.data[index];
          //   element.title = res.data[index].Nama;
          //   element.action = res.data[index].Icon;
          //   element.icon = res.data[index].action
          //   element.link = res.data[index].Route

          //   tes.push(element)
          // }
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            element.title = res.data[index].menu.Nama;
            element.icon = res.data[index].menu.Icon
            element.link = res.data[index].menu.Route

            tes.push(element)
          }
          // console.log('tes',tes)
          // var id = tes.filter( function(item){return (item.Parent == null && item.Object == "List");} );
          // var id = tes.filter( function(item){return (item.menu.Parent == null && item.Object == "List");} );
          var id = tes.filter( function(item){return (item.menu.ParentId == null && item.menu.Route == null);} );
          // console.log('id',id)
          let List = []
          for (let index = 0; index < id.length; index++) {
            const element = id[index];
            element.ListMenu = tes.filter( function(item){return (item.menu.ParentId == id[index].IdMenu);} );
            List.push(element)
          }
          // console.log('list',List)
          
          var home = tes.filter( function(item){return (item.menu.ParentId == null && item.menu.Route != null );} );
          let beranda = []
          for (let index = 0; index < home.length; index++) {
            const element = home[index];
            element.title = home[index].menu.Nama
            element.icon = home[index].menu.Icon
            element.link = home[index].menu.Route
            beranda.push(element)
          }
          this.Beranda = beranda
          this.Menu = List
          }
        )
        .catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            // this.logout()
            console.log('terlogout')
          }
        })
       }
      },
      getnotifKontrak(){
       this.kontrakjt = []
        api.get('/kontrakjt?token='+this.token).then(res=>{
          if (res) {
            // console.log('f',res.data)
            let data = res.data
            data.forEach(e => {
              e.MasaKontrak = this.CekMasaKontrak(e.TanggalAkhir)
              // console.log(e.MasaKontrak)
              if (e.MasaKontrak < 8) {
                this.NotifKontrakJt(e)
              }
            });
            // this.kontrakjt = data
            this.notiflistKontraklength = data.filter(x => x.Dibaca == 0).length
            
          }
        })
      },
      NotifKontrakJt(e){
        this.kontrakjt.push({
          Kategori:'Info',
          WarnaBackground:"blue darken-4",
          Icon:"mdi-information-outline",
          Judul:'Perpanjang Kontrak',
          Konten:'Kontrak Karyawan '+e.karyawan.Nama+' Tersisa '+e.MasaKontrak+' Hari',
          Attachment:null,
          Dari:e.KodeKaryawan,
          karyawan:{
            Nama:e.karyawan.Nama,
            KodeKaryawan:e.karyawan.KodeKaryawan
          },
          Tujuan:this.$user.KodeKaryawan,
          Dibaca:0,
        })
        // console.log('notif nih',e)
        // console.log(this.kontrakjt)
      },
      getnotif:function () {
        // console.log('jalan')
      if (this.token != null) {
        // console.log('pog',Object.keys(this.$user).length)
        let KodeKaryawan = Object.keys(this.$user).length != 0 ? this.$user.KodeKaryawan.replace('/','-') : setTimeout(() => {this.$user.KodeKaryawan.replace('/','-')}, 3000);
        let idJabatan = this.$user.jabatan.id
        let Dept = this.$user.jabatan.Departemen
        this.notiftoday(KodeKaryawan,idJabatan,Dept)
        }
      },
      // HapusNotiflist(e){
      //   let index =  this.notiflist.findIndex(i => i.Konten == e.Konten)
      //   this.notiflist = this.notiflist.splice(index,1)
      //   console.log('indexnya',index)
      // },
      TampilkanNotifHariIni(){
        this.HariIni = true
        this.BelumTerbaca = false
        this.SemuaPesan = false
        this.TextGetNotif = "notifikasiusertoday"
        this.loading = true
        this.getnotif()
      },
      TampilkanNotifBelumTerbaca(){
        this.HariIni = false
        this.BelumTerbaca = true
        this.SemuaPesan = false
        this.TextGetNotif = "notifikasibelumterbaca"
        this.loading = true
        this.getnotif()
      },
      TampilkanSemuaNotif(){
        this.HariIni = false
        this.BelumTerbaca = false
        this.SemuaPesan = true
        this.TextGetNotif = "notifikasiuser"
        this.loading = true
        this.getnotif()
      },
      notiftoday(KodeKaryawan,idJabatan,Dept){
        let data = []
        api.get('/'+this.TextGetNotif+'/'+KodeKaryawan+'/'+idJabatan+'/'+Dept+'?token='+this.token,{
          KodeKaryawan:this.$user.KodeKaryawan,
          KodeJabatan:this.$user.jabatan.KodeJabatan,
          Divisi:this.$user.jabatan.Divisi,
          NamaJabatan:this.$user.jabatan.NamaJabatan,
          KodePerusahaan:this.$user.jabatan.KodePerusahaan,
          Departemen:this.$user.jabatan.Departemen
          })
        .then(res=>{
          // console.log(res.data)
          if (res.data.length > 0) {         
            //do nothing
            //  console.log('notif',res.data)
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              switch (element.Kategori) {
                  case "Approval":
                  element.WarnaBackground = "success"
                  element.Icon = "mdi-check-circle"
                    break;
                  case "Warning":
                  element.WarnaBackground = "error"
                  element.Icon ="mdi-alert-outline"
                    break;
                  case "Info":
                  element.WarnaBackground = "blue darken-4"
                  element.Icon ="mdi-information-outline"
                    break;
                  default:
                    element.WarnaBackground = "grey darken-3"
                    element.Icon = "mdi-message"
                }
                if (!("Notification" in window)) {
                    alert("Ga Support Notifikasi");
                  }
                  // Let's check whether notification permissions have already been granted
                  else if (Notification.permission === "granted") {
                    // If it's okay let's create a notification
                    if (element.Dibaca == 0 && !this.notified.includes(element.id)) {
                      this.notified.push(element.id)
                      const greeting = new Notification(element.Judul,{
                            body: element.Konten,
                            data: element,
                            icon: this.ServerIcon
                              });
                            greeting.addEventListener('click', function(){
                            // localStorage.setItem('query',JSON.stringify(greeting.data))
                            // console.log('dat',greeting)
                        });
                    }
                  }
                  // Otherwise, we need to ask the user for permission
                  else if (Notification.permission !== "denied") {
                    Notification.requestPermission().then(function (permission) {
                      // If the user accepts, let's create a notification
                      if (permission === "granted") {
                        if (element.Dibaca == 0 && !this.notified.includes(element.id)) {
                            this.notified.push(element.id)
                            const greeting = new Notification(element.Judul,{
                                  body: element.Konten,
                                  data: element,
                                  icon: this.ServerIcon
                                    });
                                  greeting.addEventListener('click', function(){
                                  // localStorage.setItem('query',JSON.stringify(greeting.data))
                                  // console.log('dat',greeting)
                              });
                          }
                      }
                    });
                  }
                  
              data.push(element)
            }
            data = this.TextGetNotif == 'notifikasiusertoday' ? data.filter(x => x.Dibaca == 0) : data
            data = data.reverse()
          }
          this.notiflist =  data.concat(this.kontrakjt)
          this.notiflistlength = data.filter(x => x.Dibaca == 0).length
          localStorage.setItem('notified',JSON.stringify(this.notified))
          this.loading = false
        })
      },

      CekMasaKontrak(tglakhir){
        var TanggalSekarang = new Date()
        var Hari = String(TanggalSekarang.getDate()). padStart(2, '0')
        var Bulan = String(TanggalSekarang.getMonth() + 1).padStart(2, '0')
        var Tahun = TanggalSekarang.getFullYear();
        const SatuHari = 24 * 60 * 60 * 1000; // jam*menit*detik*milidetik
        const TanggalAwal = new Date(tglakhir)
        const TanggalAkhir = new Date(Tahun + '-' + Bulan + '-' + Hari)
        let HitungHari = Math.round(Math.abs((TanggalAwal - TanggalAkhir) / SatuHari))
        // this.MasaKontrak = HitungHari
        HitungHari = TanggalAkhir > TanggalAwal ? 0 : HitungHari
        // if (HitungHari < 8) {this.ShowValidasiKontrak = true,console.log('kk',this.ShowValidasiKontrak)}
        return HitungHari;
      },
      Toast(data){
        this.$swal.fire({
        toast: true,
        icon: 'success',
        title: data,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true})
      },
      ToastDibaca(){
        this.$swal.fire({
        toast: true,
        icon: 'success',
        title: 'Ditandai Sebagai Dibaca',
        animation: false,
        position: 'bottom',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true})
      },
      UpdateNotif(){
        alert('ok notif')
        // setInterval(function(){ alert("Hello"); }, 3000);
      },
      logout(){
        
        api.post('/logout?token='+this.token,{token:this.token})
        .then(res=>{
          // console.log(res)
          if (res) { 
            this.MenuShowAkun = false
            this.DialogKetikaLogout = true
            this.Toast('GoodBye 👋')
            setTimeout(() => {
              location.reload('/login')
            }, 1500);
            //do nothing
          }
        }).catch(err=>{
          // alert(err)
          if (err) {
            this.MenuShowAkun = false
            this.DialogKetikaLogout = false
            this.Toast('GoodBye 👋')
            setTimeout(() => {
              location.reload('/login')
            }, 1500);
          }
        })
        this.token = null
        this.Acc = []
        // this.$router.push('/login')
        this.valid = false
        this.MenuShowAkunMobile = false
        localStorage.removeItem('Menu')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('list')
        localStorage.removeItem('notified')
        // location.reload(false)
      },
    },
  }
</script>

<style scoped>
  .BorderBottomAppbarLight {
    border-bottom: 0.30px solid #BDBDBD
  }
  .BorderBottomAppbarDark {
    border-bottom: 0.30px solid #424242
  }
  .underline {
    border-bottom: 2px solid #5c5c5c !important;
  }
  /* .blur {
    backdrop-filter: blur(10px);
  } */
  .v-navigation-drawer {
    will-change: initial;
  }
  .NoneTransition {
    transition: none !important
  }
  .v-btn--active.no-active::before {                                                                             
    opacity: 0 !important;
  }
  .v-card__text {
    overflow: auto;
  }
  .v-bottom-navigation--fixed {position: fixed !important;}
</style>